
$.fn.isOnScreen = function(){
    var element = this.get(0);
    var bounds = element.getBoundingClientRect();
    return bounds.top < window.innerHeight && bounds.bottom > 0;
};

$.fn.scrollTo = function(){
    var target = this;
    if( target.length ){
        $('html, body').stop().animate({
            scrollTop: target.offset().top - 75
        }, 1000, 'easeInOutExpo');
    }
};

/**
 * Frontpage 'Go' button
 *
 */
var $goButton = $('.fl-container');
if($goButton.length>0){
    var $stickyGoButton = $('#stickygo');
    if(!($stickyGoButton.length>0)){
        $stickyGoButton = $goButton.clone(true);
        $stickyGoButton.appendTo('body');
        $stickyGoButton = $stickyGoButton.wrap('<div id="stickygo"/>').parent();
    }
    $(window).on('scroll',function () {
        if(!$goButton.isOnScreen()){ $stickyGoButton.toggleClass('slideup',true); }
        else{ $stickyGoButton.toggleClass('slideup',false); }
    });
}


/**
 * Anchor scroll
 */

$("a[href*='#']").on('click', function(event) {
    var target = $($(this).prop("hash"));

    if( target.length ) {
        slideout.close();
        event.preventDefault();
        target.scrollTo();
    }
});

// SEE BELOW
//if(window.location.hash) {
//
//    var target = $('a[name="' + window.location.hash.replace('#', '') + '"]');
//    if(target.length > 0) {
//        target.scrollTo();
//        console.log("Go TO", target);
//    }
//
//}

$('.my-skin input[type=radio]').click(function(e) {
    e.preventDefault();
});

/**
 * It has to run right away to prevent the default scrolling
 * then use the scrollOnLoad function
 *
 * @type {*|jQuery|HTMLElement}
 */
var hashtag = (window.location.hash);
window.location.hash = "";

function scrollOnLoad() {

    // Support two different formats for scrolling....
    var target = $('a[name="' + hashtag.replace('#', '') + '"]');
    if (target.length === 0) target = $(hashtag);

    if(target.length > 0) {
        target.scrollTo();
        target = "";
    }

}

function closeModalWindow(className) {
    $(className + ' a').click(function(e) {
        e.preventDefault();
        $(className).removeClass('animate-in');
    })
}

$(document).ready(function(){
    scrollOnLoad();

    $('.datepicker').datepicker({
        language: 'hu',
        format: 'yyyy-mm-dd',
        startView : 3,
        startDate: new Date('1900','01','01'),
        endDate: new Date(),
    });
});


//SHOW MORE / LESS
$(document).ready(function () {
    // Configure/customize these variables.
    var showChar = 152;  // How many characters are shown by default
    var ellipsestext = "...";
    var moretext = "Bővebben »";
    var lesstext = "Kevesebb «";

    $('.more').each(function () {
        /*
        var content = $(this).html();

        if (content.length > showChar) {

            var c = content.substr(0, showChar);
            var h = content.substr(showChar, content.length - showChar);

            var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

            $(this).html(html);
        }
*/
    });

    $(".morelink").click(function () {
        if ($(this).hasClass("opened")) {
            $(this).removeClass("opened");
            $(this).parent().find('.morecontent').hide();
            $(this).html('bővebben »');
        } else {
            $(this).addClass("opened");
            $(this).html('« elrejt');
            $(this).parent().find('.morecontent').show();
        }
        return false;
    });
});
