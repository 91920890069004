var slideout;
(function() {
    slideout = new Slideout({
      'panel': document.getElementById('panel'),
      'menu': document.getElementById('menu'),
      'padding': 300,
      'tolerance': 70
    });

    const slideoutToggle = document.querySelectorAll('.navigation-toggle-mobile');
    const overlay = document.querySelector('.navigation-overlay');
    const html = document.documentElement;

    for (let toggle of slideoutToggle) {
        toggle.addEventListener('click', slideout.toggle.bind(slideout));
    }

    slideout.on('beforeopen', () => {
        overlay.classList.remove('hide');
        requestAnimationFrame(() => {
            overlay.classList.add('show');
        });
    });

    slideout.on('beforeclose', () => {
        requestAnimationFrame(() => {
            html.classList.add('slideout-closing');
            overlay.classList.remove('show');
            overlay.classList.add('hiding');
        });
    });

    slideout.on('close', () => {
        overlay.classList.add('hide');
        html.classList.remove('slideout-closing');
        overlay.classList.remove('hiding');
    });
}());
