class AddToBasket {
    constructor(selector) {
        $(selector).on('click', (event) => {
            event.preventDefault();

            const $target = $(event.currentTarget);
            const pid = $target.data('pid');
            const oid = $target.data('oid');
            const q = $(`#quantity-${pid}`).val();

            this.showLoader($target);

            $('.js_basket-popup .quantity').text(q);

            $.ajax({
                url: window.api.orderAdd,
                method: 'POST',
                data: {
                    '_token': window.csrfToken,
                    pid,
                    oid,
                    q
                }
            }).then(this.onCartSuccess.bind(this, $target), console.error.bind(console));
        });
    }

    showLoader($button) {
        $button.find('.js_icon').addClass('hidden');
        $button.find('.js_icon-loader').removeClass('hidden');
    }

    hideLoader($button) {
        $button.find('.js_icon').removeClass('hidden');
        $button.find('.js_icon-loader').addClass('hidden');
    }

    showMessage() {
        var $message = $('.js_basket-success, .js_basket-popup');
        $message.removeClass('hidden').addClass('animate-in');

        setTimeout(() => {
            $message.removeClass('animate-in').addClass('animate-out');
            setTimeout(() => {
                $message.not('.js_basket-popup').addClass('hidden').removeClass('animate-out');
            }, 1000);
        }, 3000);
    }

    onCartSuccess($button, totalQuantity) {
        var $cartQuantity = $('#cart-quantity');

        $cartQuantity.html(totalQuantity);

        if (totalQuantity > 0) {
            $cartQuantity.show();
        } else {
            $cartQuantity.hide();
        }

        this.hideLoader($button);
        this.showMessage();
    }
}

$(() => {
    new AddToBasket('.js_add-to-cart');
});
